import loadscript from '@/utils/loadscript'
import type { Klaro } from './types'

const KLARO_SCRIPT_SRC =
  'https://api.kiprotect.com/v1/privacy-managers/45d9179def48e1744fa5188db10976b8/klaro.js'

let loadPromise: Promise<Klaro>

export const getKlaro = (): Promise<Klaro> => {
  if (import.meta.env['VUE_APP_KLARO_CONSENT'] === 'off') {
    return Promise.reject(new Error('Klaro consent disabled for env'))
  }
  if (window.klaro) {
    return Promise.resolve(window.klaro)
  }
  if (loadPromise) {
    // reusing the old promise means all calls
    // waiting will get flushed
    return loadPromise
  }
  // promisifies klaro load so it can be waited on elsewhere
  loadPromise = new Promise((resolve, reject) => {
    loadscript({
      src: KLARO_SCRIPT_SRC,
      defer: true,
      onload: () => {
        const klaro = window.klaro
        if (!klaro) {
          reject(new Error('Klaro loaded but not in global scope'))
          return
        }
        const manager = klaro.getManager()
        if (import.meta.env['VUE_APP_MODE'] === 'development') {
          // required for klaro to work locally
          manager.store.cookieDomain = 'localhost'
        }
        resolve(klaro)
      },
      onerror: reject,
    })
  })
  return loadPromise
}
