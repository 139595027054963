import { getKlaro } from './getKlaro'
import type { Watcher } from './types'

export const getKlaroConsentAsync = async (
  integration: string
): Promise<boolean> => {
  const klaro = await getKlaro().catch(() => {})

  if (!klaro) {
    // if klaro is not available, continue as if
    // no consent given
    return Promise.resolve(false)
  }

  const manager = klaro.getManager()

  return new Promise(resolve => {
    const hasConsent = () => manager.getConsent(integration)

    if (manager.confirmed) {
      // the manager is already confirmed
      // check consent and return
      resolve(hasConsent())
      return
    }

    // set up a watcher for state change
    const watcher: Watcher = {
      update: obj => {
        if (obj.confirmed) {
          // if confirmed, check consent again
          resolve(hasConsent())
          manager.unwatch(watcher)
        }
      },
    }

    manager.watch(watcher)
  })
}
