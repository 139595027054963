import { enAi as studyMessages } from '@/components/researcher/StudyMessages/labels'
import errors from '@/i18n/translations/en-x-ai/errors'
import invalidParticipantIdErrors from '@/i18n/translations/en-x-ai/invalidParticipantIdErrors'

// Global i18n - for use outside of components
export default {
  ...errors,
  ...invalidParticipantIdErrors,
  ...studyMessages,
}
