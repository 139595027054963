import type { MessageCategory } from './types'

export const en = {
  studyMessages: {
    errors: {
      permission_denied:
        "You don't have permission to view messages for this study.",
      unavailable:
        'You appear to be offline. Please check your connection and try again.',
      no_study:
        'Sorry, we were unable to retrieve information from this study. Please try again later.',
      general:
        'Sorry, we were unable to retrieve the messages for this study. Please try again later.',
      missing_participant_ids:
        'You need to select at least one participant to send a message.',
      cannot_send_message:
        'Sorry, we were unable to send your message. Please refresh and try again.',
    },
    categories: {
      researcher: {
        'payment-timing': 'Payment times',
        'payment-issues': 'Payment-related queries',
        'technical-issues': 'Technical queries',
        feedback: 'Study feedback',
        rejections: 'Rejection queries',
        other: 'Other',
      } satisfies Record<MessageCategory, string>,
      participant: {
        'payment-timing': 'When will I receive payment for the study?',
        'payment-issues': "I'm experiencing payment issues",
        'technical-issues': "I'm having technical issues with the study",
        feedback: 'I have feedback on the study',
        rejections: 'I have a question about rejections',
        other: 'Other',
      } satisfies Record<MessageCategory, string>,
    },
  },
}

export const enAi = {
  studyMessages: {
    errors: {
      permission_denied:
        "You don't have permission to view messages for this task.",
      no_study:
        'Sorry, we were unable to retrieve information from this task. Please try again later.',
      general:
        'Sorry, we were unable to retrieve the messages for this task. Please try again later.',
      missing_participant_ids:
        'You need to select at least one tasker to send a message.',
    },
    categories: {
      researcher: {
        feedback: 'Task feedback',
      } satisfies Partial<Record<MessageCategory, string>>,
    },
  },
}
