// my log does not judge
const isEnabled = import.meta.env.VUE_APP_MARGARET_LANTERMAN === 'on'

export default {
  isEnabled,
  log(...args) {
    if (!isEnabled) {
      return
    }
    // eslint-disable-next-line
    console.log(...args)
  },
  error(...args) {
    if (!isEnabled) {
      return
    }
    // eslint-disable-next-line
    console.error(...args)
  },
}
