import logger from '@/utils/logger'

const colors = [
  'teal',
  'orangered',
  'sienna',
  'deepskyblue',
  'brown',
  'blueviolet',
  'deeppink',
  'lime',
] as const

type CallRef = {
  name: string
  prop?: string | symbol
  args: unknown[]
}

type Color = (typeof colors)[number]

const usedColors: Map<string, Color> = new Map()

const getColor = (name: string) => {
  if (usedColors.has(name)) {
    return usedColors.get(name)
  }

  const availableColors = colors.filter(
    color => ![...usedColors.values()].includes(color)
  )
  const randomColor =
    availableColors[Math.floor(Math.random() * availableColors.length)]

  if (randomColor) {
    usedColors.set(name, randomColor)
    return randomColor
  }

  return colors[0]
}

function formatCallToString({ name, prop, args }: CallRef) {
  const formattedArgs = Array.from(args)
    .map(arg =>
      JSON.stringify(arg, (_key, val) => {
        if (typeof val === 'function') {
          return val.toString()
        }
        return val
      })
    )
    .join(', ')
  return `${name}${prop ? `.${prop.toString()}` : ''}(${formattedArgs})`
}

export const debug = (message: string, { name, prop, args }: CallRef) => {
  if (!logger.isEnabled) {
    return
  }
  const color = getColor(name)
  const formattedCall = formatCallToString({ name, prop, args })
  logger.log(`${message}: %c${formattedCall}`, `color: ${color}`)
}
