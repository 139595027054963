type Fn = () => void
export const createQueue = () => {
  const queue: Fn[] = []

  return {
    get() {
      return queue
    },

    push(fn: Fn) {
      queue.push(fn)
    },

    flush() {
      queue.forEach(fn => fn())
      queue.length = 0
    },
  }
}
