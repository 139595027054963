import type { ScriptHTMLAttributes } from 'vue'

const defaultOptions = {
  async: true,
}

type LowercaseKeys<T> = {
  [K in keyof T as K extends string ? Lowercase<K> : never]: T[K]
}

type Options = LowercaseKeys<
  Pick<ScriptHTMLAttributes, 'src' | 'onLoad' | 'onError' | 'async' | 'defer'>
>

export default function (options: Options, parent = document.head) {
  options = { ...defaultOptions, ...options }
  const script = document.createElement('script')
  Object.assign(script, options)
  parent.appendChild(script)
}
