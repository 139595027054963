export default {
  payments: {
    notifications: {},
    errors: {
      braintree: {
        failed_to_connect:
          'There is a problem connecting to the payment gateway. Please try again later.',
      },
      topups: {
        generic_topup_error:
          'Top-up failed. Please check your card details and try again.',
        three_d_secure_failed: 'Card verification failed. Please try again.',
        unsupported_card_error:
          "The card type you used isn't currently supported. Please try a different card or contact Prolific Support.",
        transaction_creation_error:
          "There's been an issue processing your transaction.",
        transaction_processing_error:
          "There's been an issue processing your transaction. Please contact Prolific Support.",
        invalid_details_error:
          "There's been an issue processing your transaction. Check your card details and try again. If this error persists, please contact your bank.",
        transaction_rejected_by_bank_error:
          "There's been an issue processing your transaction. Please contact your bank for help.",
        insufficient_funds_error:
          'Insufficient funds in your account. Please add funds and try again.',
        withdrawal_limit_exceeded_error:
          'The withdrawal limit for your card has been exceeded.',
        activity_limit_exceeded_error:
          'The activity limit for your card has been exceeded.',
        expired_card_error:
          'The card used has expired. Please use a valid card.',
        invalid_card_number_error: 'The card number you entered is invalid.',
        invalid_card_expiration_error:
          'The card expiration date is invalid or your card has expired.',
        no_account_with_bank_error:
          'There is no account associated with the provided card details.',
        invalid_card_length_error:
          'The card number entered is of invalid length.',
        no_issuer_error: 'No issuer found for the provided card.',
        invalid_cvv_error: 'The CVV number entered is invalid.',
        voice_auth_error: 'Voice authorization failed. Please try again.',
        restricted_card_error:
          'The card used is restricted for this type of transaction.',
        duplicate_transaction_error:
          'A duplicate transaction has been detected. Please contact support if this is incorrect.',
        customer_cancellation_error:
          'Transaction cancelled by customer. Please try again if this is incorrect.',
        transaction_not_supported_error:
          'This type of transaction is not supported.',
        generic_card_error: 'An error occurred with the card used.',
        cannot_process_error: 'The transaction cannot be processed.',
        unsupported_card_type_error: 'The card type used is not supported.',
        processing_amount_error:
          'There is an error processing the transaction amount.',
        prolific_account_error:
          'There is an error with the merchant account. Please contact Prolific Support.',
        card_verification_error:
          'There was an error during card verification. Please try again.',
        surcharge_error:
          'Surcharge amount not permitted. Please use a different payment method.',
        incomplete_transaction_error:
          'The transaction was not completed successfully.',
        partial_approval_error:
          'There was an error authorizing the transaction. Please try again.',
        authorization_error:
          'There was an error authorizing the transaction. Please try again.',
        invalid_authorization_code_error:
          'There was an error authorizing the transaction. Please try again.',
        call_for_approval_error:
          'The transaction requires manual approval. Please contact your bank.',
        lost_or_stolen_card_error:
          'The card has been reported as lost or stolen. Please contact your bank.',
        invalid_amount_error: 'The transaction amount is invalid.',
        failed_security_error: 'The transaction failed security checks.',
        generic_refund_error: 'An error occurred during the refund process.',
        transaction_division_error:
          'There was an error dividing the transaction.',
        card_restriction_error:
          'There is a restriction on the card for this type of transaction.',
        '3ds_required_error':
          '3D Secure verification is required for this transaction.',
        address_verification_error:
          'There was an error during address verification. Please check your details.',
        address_and_cvv_verification_error:
          'There was an error during address and CVV verification. Please check your details.',
        invalid_payment_method_error: 'The payment method used is invalid.',
        customer_revoked_authorization_error:
          'The customer has revoked the authorization for this transaction.',
        customer_paypal_account_not_finished_error:
          'The customer PayPal account is not finished. Please complete the process.',
        generic_paypal_error:
          'An error occurred with PayPal. Please try again.',
        paypal_domestic_transaction_error:
          'There was an error with the PayPal domestic transaction.',
        phone_number_required_error:
          'A phone number is required for this transaction.',
        paypal_tax_info_required_error:
          'Tax information is required in PayPal. Please complete the process.',
        paypal_transaction_too_large_error:
          'The PayPal transaction amount exceeds the allowed limit.',
        currency_not_allowed_error:
          'The selected currency is not allowed for this transaction.',
        paypal_currency_mismatch_error:
          'There is a currency mismatch with your PayPal account. Please check your account or use a different payment method.',
        generic_us_card_error:
          'An error occurred with the card used. Please try a different card.',
        paypal_payment_already_completed_error:
          'The PayPal payment has already been completed.',
        paypal_partial_refund_error:
          'There was an error during the partial refund process with PayPal.',
        '3ds_not_completed_error':
          '3D Secure verification was not completed or failed. Please try again.',
        incorrect_pin_error: 'The entered PIN is incorrect.',
        pin_too_many_attempts_error:
          'Too many incorrect PIN attempts. Please try again later.',
        card_not_activated_error:
          'The card is not activated. Please contact your bank for activation.',
        closed_card_error:
          'The card has been closed. Please contact your bank for assistance.',
        processor_network_unavailable_error:
          'The processor network is currently unavailable. Please try again later.',
      },
    },
  },
}
