export default {
  errors: {
    id_list: 'Please enter a comma-separated list of valid Prolific ids',
    bonus_payments:
      'Please enter a comma-separated list of valid Prolific ids, followed by an amount',
    email: 'Please enter a valid email address',
    url: 'Please enter a valid URL',
    required: 'This field is required',
    min_length: '{field} must be at least {length} letters',
    max_length: '{field} must be under {length} characters',
    min_number: 'Must be at least {number}',
    passwords_must_match: 'Passwords must match',
    oauth_clock_skew:
      'Please ensure that your system clock is set to the correct date, time and timezone.',
    quota_validation_error:
      'Please set a quota or change your study distribution to Standard sample.',
  },
}
