import Rollbar from 'rollbar'
import { createProxy } from './helpers/createProxy'
import { getKlaroConsentAsync } from './klaro/getKlaroConsentAsync'

declare global {
  interface Window {
    rollbar?: RollbarInstance
  }
}

type RollbarInstance = InstanceType<typeof Rollbar>

const accessToken = import.meta.env[
  'VUE_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN'
]

// importing Rollbar adds `window.rollbar` which messes up the
// proxy so deleting it and re-adding below after proxy setup
delete window.rollbar

const rollbar = createProxy<RollbarInstance>({
  name: 'rollbar',
  klaroConsentName: 'rollbar',
  isDisabled: !accessToken,
})

;(async () => {
  if (!accessToken) {
    return
  }
  const hasConsent = await getKlaroConsentAsync('rollbar')
  if (!hasConsent) {
    return
  }
  window.rollbar = new Rollbar({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    hostSafeList: ['prolific.co', 'prolific.com'],
    // The below config will make Rollbar log all
    // req/res body and headers. However, it does
    // this for ALL requests and as such as is a
    // security and GDPR concern. So disabling it
    // until further review.
    // autoInstrument: {
    //   network: true,
    //   log: true,
    //   dom: true,
    //   navigation: true,
    //   connectivity: true,
    //   networkResponseHeaders: true,
    //   networkResponseBody: true,
    //   networkRequestBody: true
    // },
    payload: {
      environment: import.meta.env['VUE_APP_MODE'],
      client: {
        javascript: {
          code_version: import.meta.env['VUE_APP_GIT_HEAD_SHA'],
          source_map_enabled: true,
          guess_uncaught_frames: true,
        },
      },
      server: {
        root: '../../',
      },
    },
  })
})()

export default rollbar
