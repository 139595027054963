export default {
  notifications: {
    general_error: 'Sorry, something went wrong. Please try again later.',
    success: 'Success',
    error: 'Error',
    info: 'Information',
    profile_updated: 'Profile updated',
    account_topped_up: 'Your account was topped by by {top_up_amount}',
    braintree_init_error:
      'There is a problem connecting to the payment gateway. Please try again later.',
    topup_failed: 'Top-up failed. Please check your details and try again',
    get_saved_payments_error:
      'Sorry, there was a problem with retrieving your saved cards.',
    delete_saved_payment_error:
      'Sorry, there was a problem with deleting your saved card. Please try again later',
    password_changed: 'Password changed',
    reset_instructions_sent: 'Reset password instructions sent',
    confirm_password_error: 'Entered password is incorrect. Please try again',
    unrecognized_user_type: 'Unrecognised user type',
    verification_error:
      'Sorry, we could not verify your email address. Please make sure you copied the verification URL in full and try again.',
    verification_success: 'Your email address has been verified.',
    verification_success_approved:
      'Your email address has been verified and your account approved.',
    increase_places_success: 'Places increased successfully.',
    increase_reward_success: 'Reward increased successfully.',
    you_must_be_logged_in: 'You must be logged in to view this page',
    problem_with_account:
      'There is a problem with your account, please contact support',
    invalid_role:
      'You need to be registered as a {requiredRole} to access this page',
    message_sent: 'Message sent',
    message_marked_read: 'Message is now read',
    message_marked_unread: 'Message is now unread',
    message_archived: 'Message archived',
    message_unarchived: 'Message unarchived',
    message_deleted: 'Message deleted',
    message_restored: 'Message restored',
    bonus_payments_sent: 'Bonus payments sent',
    reward_per_hour_adjusted: 'Reward per hour adjusted',
    active_submission:
      'Please either finish or return this submission before continuing.',
    email_verification_sent_title: 'Verification email sent',
    email_verification_sent_description: 'Your email was sent to {email}',
    cashout_email_verification_sent:
      'Cash out verification email sent. Please check your inbox.',
    phone_verified: 'Phone verified',
    cashed_out: 'Cash out successful',
    is_awaiting_activation:
      'New participant signups are currently paused for a few days, due to changes to our security review processes. To help us unlock your account faster please complete the phone verification process. Our apologies for the inconvenience.',
    is_non_oecd_country:
      'Sorry, but your country of residence is not currently supported. We will contact you when we launch in your country.',
    export_submissions_successful:
      'Your export should arrive shortly. Please check your email.',
    email_invoice_successful:
      'Your invoice should arrive shortly. Please check your email.',
    coupon_redeemed_title: 'Credit added',
    coupon_redeemed_description:
      'Credit has been successfully added to your account.',
    trial_study_publish_error:
      'We could not publish your Welcome Study due to a technical issue on our end. Please return to your Workspace and try again another time',
    mfa: {
      removed_title: 'Multifactor authentication removed',
      removed_description: "You've successfully removed MFA from your account.",
    },
  },
}
