/**
 * Gets an object on global window scope asynchronously by resolving
 * as soon as it is defined. If this doesn't happen within TIMEOUT
 * it will be rejected to avoid hanging promises.
 */
const TIMEOUT = 10000

export const getGlobal = <T extends object>(
  name: string,
  readyCallbackName?: string
): Promise<T> =>
  new Promise((resolve, reject) => {
    // get integration from global scope
    const getGlobalObj = () =>
      (window as typeof window & { [key: string]: T })[name]

    const resolveOnceReady = () => {
      let integration = getGlobalObj()

      if (!integration) {
        return
      }

      const hasReadyCallback =
        readyCallbackName &&
        readyCallbackName in integration &&
        typeof integration[readyCallbackName as keyof T] === 'function'

      if (hasReadyCallback) {
        /**
         * Sometimes integrations have a ready callback, like DD_RUM.onReady(() => { // do stuff })
         * In this situation, we need to wait until ready before resolving the promise
         * so that calls are proxied only _after_ the integration is ready.
         */
        const onReady = integration[readyCallbackName as keyof T] as (
          fn: () => void
        ) => void

        onReady(() => {
          // take from global scope again in case of mutation
          integration = getGlobalObj()
          if (integration) {
            resolve(integration)
          }
        })
        return
      }

      // else we can resolve immediately
      resolve(integration)
    }

    if (getGlobalObj()) {
      // integration is already available
      resolveOnceReady()
      return
    }

    setTimeout(() => {
      reject(new Error(`Global not found within ${TIMEOUT}ms`))
    }, TIMEOUT)

    Object.defineProperty(window, name, {
      configurable: true,
      set(value: T) {
        Object.defineProperty(window, name, {
          configurable: true,
          writable: true,
          value,
        })
        resolveOnceReady()
      },
    })
  })
