import { en as studyMessages } from '@/components/researcher/StudyMessages/labels'
import el from '@/i18n/translations/en/el'
import errors from '@/i18n/translations/en/errors'
import invalidParticipantIdErrors from '@/i18n/translations/en/invalidParticipantIdErrors'
import messages from '@/i18n/translations/en/messages'
import notifications from '@/i18n/translations/en/notifications'
import payments from '@/i18n/translations/en/payments'

// Global i18n - for use outside of components
export default {
  ...errors,
  ...notifications,
  ...el,
  ...invalidParticipantIdErrors,
  ...messages,
  ...payments,
  ...studyMessages,
}
